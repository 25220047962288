.root {
  width: 400px;
  margin: auto;
  padding: 20px;
  height: 600px;
  overflow-y: scroll;
  background: white;
}

.typographySubTitle {
  font-weight: bold;
  font-size: 18;
  color: black;
}

.title {
    font-weight: bold;
    font-size: 20;
    color: rgb(73, 0, 0);
}

.span {
    font-size: 12px;
    font-weight: bolder;
    font-style: italic;
}
